<template>
    <div :class="CSSClasses">
        <div class="wrapper">
            <div class="intro">
                <div class="copy">
                    <div class="meta">
                        <TypeLabel :name="$t('collection')" />
                    </div>
                    <Typography as="div" class="title" variant="h2">{{
                        titleOverride ? titleOverride : title
                    }}</Typography>
                    <RichTextElements
                        v-if="dekOverride || dek"
                        :elements="dekOverride || dek"
                        pageContext="collection-block"
                    />
                    <AvatarSet v-if="contributors?.length" :people="contributors" class="credits"></AvatarSet>
                </div>
                <Button variant="secondary" :url="path + '?lang=' + pageLanguage" class="cta" :theme="themeColor">
                    {{ buttonTextOverride ? buttonTextOverride : $t('see the collection') }}
                    <template #icon>
                        <Icon class="arrow" name="arrow-forward" />
                    </template>
                </Button>
            </div>

            <div class="featured-content">
                <ContentCardBlock
                    v-for="(item, index) in featuredContentItems"
                    :related="item"
                    :key="index"
                    :pageLanguage="pageLanguage"
                    mobileHideDescription="true"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import { inject } from 'vue';
const { pageLanguage } = usePageDataStore();

const props = defineProps({
    titleOverride: {
        type: String,
        default: '',
    },
    dekOverride: {
        type: Object,
    },
    buttonTextOverride: {
        type: String,
        default: '',
    },
    targetCollection: {
        type: Object,
    },
    featuredContent: {
        type: Object,
    },
});
const { title, dek, path, contributors } = props.targetCollection;

const themeColor = inject('tierTheme', 'light');

const CSSClasses = computed(() => ['collection-block', `size-${props.size}`, `theme-${themeColor}`]);

const featuredContentItems = computed(() =>
    getContentFeedItems(props.featuredContent.feed, props.featuredContent.dynamic),
);
</script>

<style lang="scss" scoped>
.intro {
    display: flex;
    gap: var(--col-gutter);
    flex-direction: column;
    align-items: flex-start;

    @include media-query(full) {
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
    }
}

.cta {
    display: inline-flex;
}

.theme-dark {
    color: color(blanc, default, 0.7);

    .title {
        color: color(blanc);
    }
}

.h2 {
    margin-top: 0.4444444444em;
}
:deep(.h3) {
    margin-bottom: 1rem;
}

.featured-content {
    display: grid;
    gap: var(--col-gutter);
    margin-top: var(--col-gutter);

    @include media-query(mobile) {
        grid-template-columns: 1fr;
    }

    @include media-query(full) {
        grid-template-columns: repeat(3, 1fr);
    }
}
</style>
